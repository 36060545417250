import React, { useState, useEffect } from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CompanyIcon from "../icons/home.svg";
import Establishment from "../components/Establishment/Establishment";
import Header from "../components/Header/Header";

import SearchHeader from "../components/SearchHeader";
import ExploreCpf from "../components/ExploreCpf";
import ExploreCed from "../components/ExploreCed";
import ExploreTreatment from "../components/ExploreTreatment";
import ExploreTreatmentDetails from "../components/ExploreTreatmentDetails";
import NavigationBar from "../components/NavigationBar";

import "./Results.scss";

const DISPLAY = {
  NO_SELECTION: 0,
  EXPLORE_CPF: 1,
  EXPLORE_CED: 2,
  EXPLORE_TREATMENT: 3,
  EXPLORE_TREATMENT_DETAILS: 4,
};

const Results = () => {
  // hooks pour la récupération des datas
  const [dataCompanies, setDataCompanies] = useState();
  const [dataTreatments, setDataTreatments] = useState([]);

  // hooks pour le récapitulatif des selections
  const [compagnieBranchSelected, setCompagnieBranchSelected] = useState({});
  const [cpfSelected, setCpfSelected] = useState({});
  const [cedSelected, setCedSelected] = useState({});
  const [treatmentSelected, setTreatmentSelected] = useState({});

  // utilisation du hook use Location pour récupérer les données de recherches
  const location = useLocation();

  // ---------- Autres fonctions ---------- //
  // Récupération du siret ou du siren depuis l'url
  const getSire = () => {
    let { slug } = useParams();
    return slug;
  };

  // Récupération du siret au clique de la carte company voulu
  const onCompagnieBranchSelection = (item) => {
    setCompagnieBranchSelected(item);

    //réinitialisation des selections
    setCpfSelected({});
    setCedSelected({});
    setTreatmentSelected({});
  };

  // ---------- Requêtes ---------- //
  // GET  companies
  const  {REACT_APP_BACKEND_URL} = process.env;
  const urlCompanies =  REACT_APP_BACKEND_URL + `/company/${getSire()}`;
  useEffect(() => {
    if (location.state) {
      setDataCompanies(location.state);
      console.log("dataCompanies", dataCompanies);
      // setCompagnieBranchSelected(dataCompanies.branches[0]);
    } else {
      // si location.state = null
      // c'est que l'utilisateur utilise directement un lien à partir d'une url
      axios.get(urlCompanies).then((response) => {
        setDataCompanies(response.data);
      });
    }
  }, []);

  // Affichage conditionnel
  let activeDisplay;
  if (JSON.stringify(treatmentSelected) != "{}") {
    activeDisplay = DISPLAY.EXPLORE_TREATMENT_DETAILS;
  } else if (JSON.stringify(cedSelected) != "{}") {
    activeDisplay = DISPLAY.EXPLORE_TREATMENT;
  } else if (JSON.stringify(cpfSelected) != "{}") {
    activeDisplay = DISPLAY.EXPLORE_CED;
  } else if (JSON.stringify(compagnieBranchSelected) != "{}") {
    activeDisplay = DISPLAY.EXPLORE_CPF;
  } else {
    activeDisplay = DISPLAY.NO_SELECTION;
  }

  return (
    <div className="Results">
      <Header
        displayedName={dataCompanies && dataCompanies.branches[0].name}
        searchedNumber={getSire()}
      />

      <div className="Results-containerNavMain">
        <nav className="Results-sidePanel">
          {dataCompanies && dataCompanies.branches.length > 1 ? (
            <h2 className="Results-sidePanel-title">Établissements</h2>
          ) : (
            <h2 className="Results-sidePanel-title">Établissement</h2>
          )}

          <ul className="Results-establishments">
            {dataCompanies &&
              dataCompanies.branches.map((company) => (
                <Establishment
                  companySelected={compagnieBranchSelected.siret}
                  onClickEstablishment={() =>
                    onCompagnieBranchSelection(company)
                  }
                  key={company.id}
                  companyIcon={CompanyIcon}
                  companyMain={company.main}
                  companyName={company.name}
                  companyZipCode={company.zip_code}
                  companyCity={company.city}
                  companySiret={company.siret}
                  companyNafCode={company.naf.code}
                  companyNafName={company.naf.name}
                />
              ))}
          </ul>
          {!dataCompanies && (
            <p className="Results-noEstablishments">
              Aucun établissement n&apos;a été trouvé.
            </p>
          )}
        </nav>

        <main className="Results-maincontent">
          {JSON.stringify(cpfSelected) != "{}" && (
            <NavigationBar
              compagnieBranchSelected={compagnieBranchSelected}
              cpfSelected={cpfSelected}
              setCpfSelected={setCpfSelected}
              cedSelected={cedSelected}
              setCedSelected={setCedSelected}
              treatmentSelected={treatmentSelected}
              setTreatmentSelected={setTreatmentSelected}
            />
          )}

          {JSON.stringify(compagnieBranchSelected) != "{}" &&
            JSON.stringify(cpfSelected) == "{}" && (
              <div className="Results-companyHeader">
                <h1>{compagnieBranchSelected.name}</h1>
                <div className="Results-adress">
                  <h3>
                    {compagnieBranchSelected.zip_code}{" "}
                    {compagnieBranchSelected.city}
                  </h3>
                  {compagnieBranchSelected.main && (
                    <p className="Results-chipHeadCompagny">siège social</p>
                  )}
                </div>
              </div>
            )}

          {JSON.stringify(compagnieBranchSelected) == "{}" && (
            <div className="Results-pleaseSelect">
              Veuillez sélectionner un établissement
            </div>
          )}

          {JSON.stringify(cpfSelected) != "{}" && (
            <SearchHeader
              cpfSelected={cpfSelected}
              cedSelected={cedSelected}
              treatmentSelected={treatmentSelected}
            />
          )}

          {activeDisplay == DISPLAY.EXPLORE_CPF && (
            <ExploreCpf
              selectedEnterpriseSiret={compagnieBranchSelected.siret}
              setCpfSelected={setCpfSelected}
            />
          )}

          {activeDisplay == DISPLAY.EXPLORE_CED && (
            <ExploreCed
              cpfCodeSelected={cpfSelected.code}
              setCedSelected={setCedSelected}
            />
          )}

          {activeDisplay == DISPLAY.EXPLORE_TREATMENT && (
            <ExploreTreatment
              selectedEnterpriseSiret={compagnieBranchSelected.siret}
              selectedCedCode={cedSelected.code}
              setTreatmentSelected={setTreatmentSelected}
              dataTreatments={dataTreatments}
              setDataTreatments={setDataTreatments}
            />
          )}

          {activeDisplay == DISPLAY.EXPLORE_TREATMENT_DETAILS && (
            <ExploreTreatmentDetails
              dataTreatments={dataTreatments}
              setDataTreatments={setDataTreatments}
              setTreatmentSelected={setTreatmentSelected}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default Results;
