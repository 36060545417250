import React from "react";

import { ReactComponent as CedIcon } from "../icons/trash.svg";
import { ReactComponent as CpfIcon } from "../icons/settings.svg";
import { ReactComponent as CompanyIcon } from "../icons/home.svg";

import MapPin from "../icons/map-pin.svg";

import "./SearchHeader.scss";

const SearchHeader = ({ cpfSelected, cedSelected, treatmentSelected }) => {
  if (JSON.stringify(treatmentSelected) != "{}") {
    return (
      <div className="searchheader">
        <div className="searchheader-element">
          <div className="searchheader-iconcontainer searchheader-treatmentBackgroundIcon">
            <CompanyIcon className="searchheader-icon" />
          </div>

          <div className="searchheader-highlight">
            {treatmentSelected && treatmentSelected.distance && (
              <div className="searchheader-distance">
                <img src={MapPin} alt="map pin icon" />{" "}
                {treatmentSelected.distance} km
              </div>
            )}
          </div>

          <p className="searchheader-title">{treatmentSelected.name}</p>

          <div className="searchheader-details searchheader-text">
            <p>
              {treatmentSelected.zip_code} {treatmentSelected.city}
            </p>
            <p>
              <span className="searchheader-text-bold">SIRET : </span>

              {treatmentSelected.siret}
            </p>
            <p>
              <span className="searchheader-text-bold">NAF : </span>
              {treatmentSelected.naf.code} - {treatmentSelected.naf.name}
            </p>
          </div>

          {treatmentSelected.description ? (
            <p className="searchheader-text">{treatmentSelected.description}</p>
          ) : null}
        </div>
      </div>
    );
  } else if (JSON.stringify(cedSelected) != "{}") {
    return (
      <div className="searchheader">
        <div className="searchheader-element">
          <div className="searchheader-iconcontainer searchheader-cedBackgroundIcon">
            <CedIcon className="searchheader-icon" />
          </div>
          <p className="searchheader-title">CED n°{cedSelected.code}</p>
          {cedSelected.description ? (
            <p className="searchheader-text">{cedSelected.description}</p>
          ) : (
            <p className="searchheader-emptyField">
              pas de description disponible
            </p>
          )}
        </div>
      </div>
    );
  } else if (JSON.stringify(cpfSelected) != "{}") {
    return (
      <div className="searchheader">
        <div className="searchheader-element">
          <div className="searchheader-iconcontainer searchheader-cpfBackgroundIcon">
            <CpfIcon className="searchheader-icon" />
          </div>
          <p className="searchheader-title">CPF n°{cpfSelected.code}</p>
          {cpfSelected.description ? (
            <p className="searchheader-text">{cpfSelected.description}</p>
          ) : (
            <p className="searchheader-emptyField">
              pas de description disponible
            </p>
          )}
        </div>
      </div>
    );
  } else return <div></div>;
};

export default SearchHeader;
