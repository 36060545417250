import React from "react";

import LeftArrow from "../icons/arrow-left.svg";

import "./NavigationBar.scss";

const NavigationBar = ({
  compagnieBranchSelected,
  cpfSelected,
  setCpfSelected,
  cedSelected,
  setCedSelected,
  treatmentSelected,
  setTreatmentSelected,
}) => {
  const onGoingBack = () => {
    if (JSON.stringify(treatmentSelected) != "{}") {
      setTreatmentSelected({});
    } else if (JSON.stringify(cedSelected) != "{}") {
      setCedSelected({});
    } else if (JSON.stringify(cpfSelected) != "{}") {
      setCpfSelected({});
    }
  };

  const onEstablishmentClick = () => {
    setCpfSelected({});
    setCedSelected({});
    setTreatmentSelected({});
  };
  const onCpfClick = () => {
    setCedSelected({});
    setTreatmentSelected({});
  };
  const onCedClick = () => {
    setTreatmentSelected({});
  };

  return (
    <div className="navigationbar">
      <button
        className="navigationbar-returnButton"
        onClick={() => {
          onGoingBack();
        }}
      >
        <img src={LeftArrow} alt="Return button" /> <div>Retour</div>
      </button>

      <a className="navigationbar-clickable" onClick={onEstablishmentClick}>
        {compagnieBranchSelected.name} - {compagnieBranchSelected.city}
      </a>

      {JSON.stringify(cpfSelected) != "{}" && (
        <span>
          <span className="navigationbar-separator"> / </span>
          <a className="navigationbar-clickable" onClick={onCpfClick}>
            CPF n°{cpfSelected.code}
          </a>
        </span>
      )}

      {JSON.stringify(cedSelected) != "{}" && (
        <span>
          <span className="navigationbar-separator"> / </span>

          <a className="navigationbar-clickable" onClick={onCedClick}>
            CED n°{cedSelected.code}
          </a>
        </span>
      )}

      {JSON.stringify(treatmentSelected) != "{}" && (
        <span>
          <span className="navigationbar-separator"> / </span>

          <a className="navigationbar-clickable">{treatmentSelected.name}</a>
        </span>
      )}
    </div>
  );
};

export default NavigationBar;
