import React from "react";

import { ReactComponent as CpfIcon } from "../icons/settings.svg";

const Cpf = ({
  onClickCPF,
  productWeb,
  productCode,
  productDescription,
  scraping,
}) => {
  return (
    <li className="explorelayout-element" onClick={onClickCPF}>
      <div className="explorelayout-iconcontainer explorelayout-cpfBackgroundIcon">
        <CpfIcon className="explorelayout-icon" />
      </div>
      <p className="explorelayout-title">CPF n°{productCode}</p>
      {productDescription ? (
        <p className="explorelayout-text">{productDescription}</p>
      ) : (
        <p className="explorelayout-emptyField">
          pas de description disponible
        </p>
      )}
      {productWeb && (
        <p className="explorelayout-chipScraping">
          Issu de l&apos;analyse du site web
        </p>
      )}
      {scraping && (
        <p className="explorelayout-chipScraping">
          vérifié sur le site web de l&apos;entreprise
        </p>
      )}
    </li>
  );
};

export default Cpf;
