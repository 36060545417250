import React, { useEffect, useState } from "react";
import axios from "axios";

import Ced from "./Ced";
import ResultsLoading from "./ResultsLoading";

import { API_STATUS } from "../utils/enum";

import "./ExploreLayout.scss";

const ExploreCed = ({ cpfCodeSelected, setCedSelected }) => {
  const [dataWastes, setDataWastes] = useState([]);
  const [apiStatus, setApiStatus] = useState(API_STATUS.NOT_REQUESTED);

  
  const  {REACT_APP_BACKEND_URL} = process.env;
  const urlWastes =  REACT_APP_BACKEND_URL + `/product/${cpfCodeSelected}`;

  useEffect(() => {
    setApiStatus(API_STATUS.LOADING);
    axios
      .get(urlWastes)
      .then((response) => {
        setDataWastes(response.data);
        setApiStatus(API_STATUS.SUCCESS);
      })
      .catch(() => {
        setApiStatus(API_STATUS.ERROR);
      });
  }, [cpfCodeSelected]);

  return (
    <div className="explorelayout">
      <h2>Déchets susceptibles d&apos;être produits</h2>

      {apiStatus == API_STATUS.LOADING && <ResultsLoading />}

      {apiStatus == API_STATUS.SUCCESS && (
        <ul className="explorelayout-list">
          {dataWastes &&
            dataWastes.map((waste) => (
              <Ced
                onClickCED={() => setCedSelected(waste)}
                key={waste.id}
                wasteCode={waste.code}
                wasteDescription={waste.description}
              />
            ))}
          {(!dataWastes || (dataWastes && dataWastes.length == 0)) && (
            <li className="explorelayout-nodata">
              Aucune données n&apos;ont pu être trouvées
            </li>
          )}
        </ul>
      )}

      {apiStatus == API_STATUS.ERROR && (
        <div className="explorelayout-nodata">
          L&apos;API a retourné une erreur
        </div>
      )}
    </div>
  );
};

export default ExploreCed;
