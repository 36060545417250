import React from "react";

import { ReactComponent as LoadingIcon } from "../icons/loading.svg";

import "./ResultsLoading.scss";

const ResultsLoading = () => {
  return (
    <div className="resultsloading">
      <LoadingIcon className="resultsloading-icon" />
      <p className="resultsloading-text">Chargement</p>
    </div>
  );
};

export default ResultsLoading;
