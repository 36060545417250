import React from "react";

import TreatmentCompany from "./TreatmentCompany";

import "./ExploreLayout.scss";

const ExploreTreatmentDetails = ({ dataTreatments, setTreatmentSelected }) => {
  return (
    <div className="explorelayout">
      <h2>Autres établissements capable de traiter ce déchet</h2>

      <ul className="explorelayout-list">
        {dataTreatments &&
          dataTreatments.map((treatment) => (
            <TreatmentCompany
              displayTreatmentMain={true}
              displayDistance={true}
              onClickTreatmentCompany={() => {
                setTreatmentSelected(treatment);
              }}
              key={treatment.id}
              treatmentDistance={treatment.distance}
              treatmentName={treatment.name}
              treatmentZipCode={treatment.zip_code}
              treatmentCity={treatment.city}
              treatmentSiret={treatment.siret}
              treatmentNafCode={treatment.naf.code}
              treatmentNafName={treatment.naf.name}
              treatmentMain={treatment.main}
            />
          ))}
      </ul>
    </div>
  );
};

export default ExploreTreatmentDetails;
