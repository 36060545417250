import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import HomeImage from "../img/home.svg";
import ErrorIcon from "../icons/error.svg";
import InfoIcon from "../icons/info.svg";
import LoadingIcon from "../icons/loading.svg";
import Logo from "../img/logoBright.svg";

import "./Home.scss";

import { API_STATUS } from "../utils/enum";

const isSiretSirenValid = (str) => {
  const regex =
    /(^\d{9}$|^\d{9}\s\d{5}$|^\d{3}\s\d{3}\s\d{3}$|^\d{14}$|^\d{3}\s\d{3}\s\d{3}\s\d{5}$)/;
  return regex.test(str);
};

const Home = () => {
  const [title, setTitle] = useState("");
  const [isWrongEntry, setIsWrongEntry] = useState(false);
  const [apiStatus, setAPIStatus] = useState(API_STATUS.NOT_REQUESTED);

  let history = useHistory();

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  // Validation
  // On vérifie s'il on récupére des données avant de les afficher
  const onValidation = () => {
    if (isSiretSirenValid(title)) {
      (async () => {
        setAPIStatus(API_STATUS.LOADING);

        const  {REACT_APP_BACKEND_URL} = process.env;
        const urlCompanies = REACT_APP_BACKEND_URL +  `/company/${title.replace(
          /\s+/g,
          ""
        )}`;
        axios
          .get(urlCompanies)
          .then((response) => {
            const location = {
              pathname: `/results/sire=${title.replace(/\s+/g, "")}`,
              state: response.data,
            };
            history.push(location);
          })
          .catch(() => {
            setAPIStatus(API_STATUS.ERROR);
          });
      })();
    } else {
      setIsWrongEntry(true);
    }
  };

  // on désactive les alertes quand l'utilisateur edite l'entrée
  useEffect(() => {
    setIsWrongEntry(false);
    setAPIStatus(API_STATUS.NOT_REQUESTED);
  }, [title]);

  return (
    <div className="Home">
      <img className="Home-image" src={HomeImage} alt="image accueil" />
      <div className="Home-form">
        <h3>
          Entrez votre numéro
          <br />
          de
          <span className="Home-titleSpan"> SIREN </span>
          ou
          <span className="Home-titleSpan"> SIRET</span>
        </h3>

        <div className="Home-input">
          <input
            className="Home-inputText"
            type="text"
            value={title}
            placeholder={"000000000(00000)"}
            onChange={handleChange}
            maxLength={20}
          />

          {apiStatus != API_STATUS.LOADING && (
            <button className="Home-inputButton" onClick={onValidation}>
              Valider
            </button>
          )}

          {apiStatus == API_STATUS.LOADING && (
            <button className="Home-inputLoading">
              <img
                className="Home-iconLoading"
                src={LoadingIcon}
                alt="Loading icon"
              />
            </button>
          )}
        </div>

        <p className="Home-validationMessage">
          Veuillez saisir une suite de 9 (siren) ou de 14 (siret) chiffres sans
          caractères spéciaux.
        </p>

        {isWrongEntry && (
          <div className="Home-error">
            <img className="Home-icon" src={ErrorIcon} alt="Error icon" />
            <div className="Home-message">
              <p className="Home-validationMessageIsNotValid">
                La saisie ne correspond pas au format demandé
              </p>
            </div>
          </div>
        )}

        {apiStatus == API_STATUS.ERROR && (
          <div className="Home-info">
            <img className="Home-icon" src={InfoIcon} alt="Info icon" />
            <div className="Home-message">
              <p>
                L&apos;établissement au numéro de{" "}
                {title.length > 9 ? "SIRET" : "SIREN"} {title} n&apos;est pas
                disponible pour une des raisons suivantes :
                <br />- n&apos;est pas une entreprise manufacturière
                <br />- ne fait pas partie de la région Auvergne-Rhône Alpes.
                <br />- ne fait pas partie des entreprises présentes dans la
                base de données actuellement.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="Home-madeBy">
        <p>Réalisé par</p>
        <img src={Logo} alt="Logo d'Open Studio" className="Home-logo" />
      </div>
    </div>
  );
};

export default Home;
