import React, { useEffect, useState } from "react";
import axios from "axios";

import Cpf from "./Cpf";
import ResultsLoading from "./ResultsLoading";

import CpfIcon from "../icons/settings.svg";

import "./ExploreLayout.scss";

import { API_STATUS } from "../utils/enum";

const ExploreCpf = ({ selectedEnterpriseSiret, setCpfSelected }) => {
  const [dataProducts, setDataProducts] = useState([]);
  const [apiStatus, setApiStatus] = useState(API_STATUS.NOT_REQUESTED);

  const  {REACT_APP_BACKEND_URL} = process.env;
  const urlProducts = REACT_APP_BACKEND_URL + `/branch/${selectedEnterpriseSiret}/product`;

  useEffect(() => {
    setApiStatus(API_STATUS.LOADING);
    axios
      .get(urlProducts)
      .then((response) => {
        setDataProducts(response.data);
        setApiStatus(API_STATUS.SUCCESS);
      })
      .catch(() => {
        setApiStatus(API_STATUS.ERROR);
      });
  }, [selectedEnterpriseSiret]);

  return (
    <div className="explorelayout">
      <h2>Produits susceptibles d&apos;être fabriqués</h2>

      {apiStatus == API_STATUS.LOADING && <ResultsLoading />}

      {apiStatus == API_STATUS.SUCCESS && (
        <ul className="explorelayout-list">
          {dataProducts &&
            dataProducts.map((product, index) => (
              <Cpf
                CpfIcon={CpfIcon}
                key={product.id}
                scraping={index === 0}
                onClickCPF={() => {
                  setCpfSelected(product);
                }}
                productWeb={product.scraping}
                productCode={product.code}
                productDescription={product.description}
              />
            ))}
          {(!dataProducts || (dataProducts && dataProducts.length == 0)) && (
            <li className="explorelayout-nodata">
              Aucune données n&apos;ont pu être trouvées
            </li>
          )}
        </ul>
      )}

      {apiStatus == API_STATUS.ERROR && (
        <div className="explorelayout-nodata">
          L&apos;API a retourné une erreur
        </div>
      )}
    </div>
  );
};

export default ExploreCpf;
