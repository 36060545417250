import React from "react";

import { ReactComponent as CedIcon } from "../icons/trash.svg";

const Ced = ({ onClickCED, wasteCode, wasteDescription }) => {
  return (
    <li className="explorelayout-element" onClick={onClickCED}>
      <div className="explorelayout-iconcontainer explorelayout-cedBackgroundIcon">
        <CedIcon className="explorelayout-icon" />
      </div>
      <p className="explorelayout-title">CED n°{wasteCode}</p>
      {wasteDescription ? (
        <p className="explorelayout-text">{wasteDescription}</p>
      ) : (
        <p className="explorelayout-emptyField">
          pas de description disponible
        </p>
      )}
    </li>
  );
};

export default Ced;
