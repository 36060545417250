import React, { useEffect, useState } from "react";
import axios from "axios";

import TreatmentCompany from "./TreatmentCompany";
import ResultsLoading from "./ResultsLoading";

import "./ExploreLayout.scss";

import { API_STATUS } from "../utils/enum";

const ExploreTreatment = ({
  selectedEnterpriseSiret,
  selectedCedCode,
  setTreatmentSelected,
  dataTreatments,
  setDataTreatments,
}) => {
  const [apiStatus, setApiStatus] = useState(API_STATUS.NOT_REQUESTED);

  const  {REACT_APP_BACKEND_URL} = process.env;
  const urlTreatments = REACT_APP_BACKEND_URL + `/branch/${selectedEnterpriseSiret}/treatment/${selectedCedCode}`;

  useEffect(() => {
    setApiStatus(API_STATUS.LOADING);
    axios
      .get(urlTreatments)
      .then((response) => {
        setDataTreatments(response.data);
        setApiStatus(API_STATUS.SUCCESS);
      })
      .catch(() => {
        setApiStatus(API_STATUS.ERROR);
      });
  }, [selectedEnterpriseSiret, selectedCedCode]);

  return (
    <div className="explorelayout">
      <h2>Entreprises susceptibles de traiter ce déchet</h2>
      {apiStatus == API_STATUS.LOADING && <ResultsLoading />}
      {apiStatus == API_STATUS.SUCCESS && (
        <ul className="explorelayout-list">
          {dataTreatments &&
            dataTreatments.map((treatment) => (
              <TreatmentCompany
                displayTreatmentMain={false}
                onClickTreatmentCompany={() => {
                  setTreatmentSelected(treatment);
                }}
                displayDistance={true}
                key={treatment.id}
                treatmentDistance={treatment.distance}
                treatmentName={treatment.name}
                treatmentZipCode={treatment.zip_code}
                treatmentCity={treatment.city}
                treatmentSiret={treatment.siret}
                treatmentNafCode={treatment.naf.code}
                treatmentNafName={treatment.naf.name}
                treatmentMain={treatment.main}
              />
            ))}
          {(!dataTreatments ||
            (dataTreatments && dataTreatments.length == 0)) && (
            <li className="explorelayout-nodata">
              Aucune données n&apos;ont pu être trouvées
            </li>
          )}
        </ul>
      )}

      {apiStatus == API_STATUS.ERROR && (
        <div className="explorelayout-nodata">
          L&apos;API a retourné une erreur
        </div>
      )}
    </div>
  );
};

export default ExploreTreatment;
