import React from "react";

import "./theme.scss";
import "./App.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Results from "./pages/Results";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/results/sire=:slug" component={Results} />
      </Switch>
    </Router>
  );
}

export default App;
