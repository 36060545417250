import React from "react";

import { ReactComponent as CompanyIcon } from "../icons/home.svg";

import MapPin from "../icons/map-pin.svg";

const TreatmentCompany = ({
  onClickTreatmentCompany,
  displayDistance,
  treatmentMain,
  displayTreatmentMain,
  treatmentZipCode,
  treatmentCity,
  treatmentSiret,
  treatmentNafCode,
  treatmentNafName,
  displayDescription,
  treatmentDescription,
  treatmentName,
  treatmentDistance,
}) => {
  return (
    <li className="explorelayout-element" onClick={onClickTreatmentCompany}>
      <div className="explorelayout-iconcontainer explorelayout-treatmentBackgroundIcon">
        <CompanyIcon className="explorelayout-icon" />
      </div>

      <div className="explorelayout-highlight">
        {treatmentMain && displayTreatmentMain && (
          <p className="explore-chipHeadCompagny">siège social</p>
        )}
        {displayDistance && (
          <div className="explorelayout-distance">
            <img src={MapPin} alt="map pin icon" /> {treatmentDistance} km
          </div>
        )}
      </div>

      <p className="explorelayout-title">{treatmentName}</p>

      <div className="explorelayout-text">
        <p>
          {treatmentZipCode} {treatmentCity}
        </p>
        <p>
          <span className="explorelayout-text-bold">SIRET : </span>
          {treatmentSiret}
        </p>
        <p>
          <span className="explorelayout-text-bold">NAF : </span>
          {treatmentNafCode} - {treatmentNafName}
        </p>
      </div>

      {displayDescription ? (
        <p className="explorelayout-text">{treatmentDescription}</p>
      ) : null}
    </li>
  );
};

export default TreatmentCompany;
