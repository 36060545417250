import React from "react";

import "./Establishment.scss";

const Establishment = ({
  companySelected,
  onClickEstablishment,
  companyIcon,
  companyMain,
  companyName,
  companyZipCode,
  companyCity,
  companySiret,
  companyNafCode,
  companyNafName,
}) => {
  const isSelected = companySelected === companySiret;

  return (
    <li
      className={`Establishment-companyCard${isSelected ? "--selected" : ""}`}
      onClick={onClickEstablishment}
    >
      <div className="Establishment-iconChip">
        <div className="Establishment-icon">
          <img
            src={companyIcon}
            alt={companyName}
            className="Establishment-iconCompany"
          />
        </div>
        {companyMain && (
          <p className="Establishment-chipHeadCompagny">siège social</p>
        )}
      </div>

      <p className={`Establishment-title${isSelected ? "--selected" : ""}`}>
        {companyName}
      </p>

      <div className={`Establishment-text${isSelected ? "--selected" : ""}`}>
        <p>
          {companyZipCode} {companyCity}
        </p>

        <p>
          <span className="Establishment-text--bold">SIRET : </span>
          {companySiret}
        </p>

        <p>
          <span className="Establishment-text--bold">NAF : </span>
          {companyNafCode} - {companyNafName}
        </p>
      </div>
    </li>
  );
};

export default Establishment;
