import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Search } from "../../icons/search.svg";

import Logo from "../../img/badgeLogoDark.svg";

import "./Header.scss";

const Header = ({ displayedName, searchedNumber }) => {
  return (
    <header className="Header">
      <img className="Header-logo" src={Logo} alt="Badge Open Studio" />

      {displayedName ? (
        <p className="Header-text">
          {"Résultats pour l'entreprise "}
          <span className="Header-text-bold">{` ${displayedName} `}</span>
          {`( ${searchedNumber} )`}
        </p>
      ) : (
        <p className="Header-text">
          Résultats pour le n°
          <span className="Header-text-bold">{searchedNumber}</span>
        </p>
      )}

      <Link className="Header-button" to={"/"}>
        <Search />
        Faire une nouvelle recherche
      </Link>
    </header>
  );
};

export default Header;
